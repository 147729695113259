import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer } from "../styles/ArticleStyles";

const DamarHamlinNFL = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385928/samples/persnickety/nflHealth_i1nn7i.jpg" alt="The NFL Doesn't Care About Player Health & Safety" />
            {/* </ArticleImageContainer> */}
            <Title>The NFL Doesn't Care About Player Health & Safety</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>January 4th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    We almost watched a man die on our television screens.<br/><br/>

                    If you didn’t see or hear, this past Monday, Damar Hamlin of the Buffalo Bills took a hit straight to the chest while making a routine tackle. He popped right up after the hit, and a second 
                    later collapsed onto the ground, laying lifeless in front of the football world. Thanks to the heroic efforts of the Bills medical team, he was resuscitated and taken to the University of 
                    Cincinnati Medical Center. He suffered a cardiac arrest and there is no timeline or guarantee that he will fully recover.<br/><br/>

                    I know I’m going to keep watching football regardless so this is a moot point but I can’t help but feel a little guilty for loving this game so much and supporting the NFL with my viewership. 
                    Why am I so entertained by grown men bashing into each other at full speed? These people spend most of their college career practicing and playing a violent game that puts their body through 
                    rigorous punishment, to hopefully make it on to an NFL team, and take more rigorous punishment to their bodies, to entertain millions upon millions of people, which <Link className="article-link" to={"https://thefootballusa.com/nfl-revenue-year-by-year#:~:text=Total%20Revenue%20Of%20NFL,-Yearly%20NFL%20revenue&text=In%202021%2C%20the%20total%20income,million%20also%20helped%20generate%20income."} target="_blank">generates mountains</Link> of 
                    revenue as we know. Yet still, finally making it to the pinnacle of the sport is not enough to guarantee a fulfilling and stable life, despite the mountain of work and sacrifice it took to get 
                    there.<br/><br/> 

                    Damar Hamlin is in his 2nd year on the Buffalo Bills. He needs one more year to be eligible for the NFL’s long term healthcare and pension plan. Right now, he’s facing a lifetime of medical 
                    procedures and hospital visits just to keep living. The money from his rookie deal that he’s making this year isn’t gonna last him a generation. If he never plays a down of football again, he 
                    is ineligible for long term health benefits and a pension plan.<br/><br/>

                    That’s because Damar Hamlin is considered an unvested player, meaning he hasn’t had 3 active seasons in the NFL yet. On top of that, he was drafted in the 6th round as a safety. His rookie 
                    contract is all but guaranteed. After all of the money these people generate for the league playing an extremely dangerous sport, you’d think they’d at least get some type of long term care 
                    regardless of how many years they played. Unfortunately, between the barely guaranteed contracts and vested & non-vested player designations for players, the outlook is quite bleak.<br/><br/>

                    <h1>The Contracts</h1> 
                    Depending on what position you play and where you get drafted, contracts are only partially guaranteed. Meaning most rookies that enter the league have to stay healthy and perform for their 
                    supper. As of now, contract lengths and pay rates depending on where you’re drafted are fixed for all players entering the league. This was done to curb newly drafted players from holding out 
                    of training camp to negotiate a more lucrative contract for themselves. Now, you get what you get and you don’t get upset. Even if you get injured or don’t play up to the expectations of your 
                    football overlords, in which case you can kiss your money goodbye you little scrub.<br/><br/> 

                    In a league where violence is the name of the game, there are only 155 players under fully guaranteed contracts. Guarantees fall into 3 categories: skill, cap, & injury guarantees. Under a 
                    skill guarantee, players still receive their money even if they get cut due to poor performance. Injury guarantees ensure players still get their money regardless of health status. Cap 
                    guarantees protect players from losing their money as a result of being cut in order for the team to stay under the salary cap (the limit that a team can spend on all of its players). Fully 
                    guaranteed contracts consist of all three of these guarantees, however, there are certain terms in a guaranteed contract where the money only becomes so after a certain date, if the player is 
                    cut before that trigger date, so is that “guaranteed” money.<br/><br/> 

                    Damar Hamlin signed a 4 year, $3,640,476 contract with the Buffalo Bills in 2021. $160,476 of that is guaranteed. There is a split clause in his contract that would cut his yearly salary 
                    nearly in half if he were to be placed on injured reserve. If I were a betting man, I’d say he’s getting placed on injured reserve.<br/><br/>

                    <h1>Vested vs. Non-Vested Players</h1>
                    In the league, there are two types of players, vested and non-vested players. Players become vested after having notched <Link className="article-link" to={"https://nflpa.com/active-players/faq/what-is-a-credited-season-and-what-does-it-mean-to-be-vested"} target="_blank">3 credited seasons</Link> under their belt. Depending on the position you 
                    play, you’ll be chewed up and spit out of the league in <Link className="article-link" to={"https://www.statista.com/statistics/240102/average-player-career-length-in-the-national-football-league/#:~:text=The%20average%20length%20of%20a,to%20hard%20hits%20and%20collisions."} target="_blank">less than 3 years</Link>, so you won’t even sniff the added benefits given to vested players. Non-vested players have about a <Link className="article-link" to={"https://static.www.nfl.com/image/upload/v1662999410/league/j8osuyzdex5ifggog1pg.pdf"} target="_blank">page and a half</Link> of
                    benefits to look forward to after retirement, none of which include a pension plan and long-term health benefits. Once you’re done entertaining the masses and your body is battered and 
                    broken and can’t take any more punishment, if you haven’t met the vested player threshold, you get a <Link className="article-link" to={"https://static.www.nfl.com/image/upload/v1662999410/league/j8osuyzdex5ifggog1pg.pdf"} target="_blank">severance payment</Link>, some dental and vision care, and a disability plan that <Link className="article-link" to={"https://www.diattorney.com/nfl-player-disability-benefit-claims"} target="_blank">recently reduced</Link> its
                    max payouts for disability payments from $22,000 to $4,000. Tossed aside and never to be thought of again.<br/><br/> 

                    As for the minority of players that become vested before there career in the NFL is over, there are <Link className="article-link" to={"https://static.www.nfl.com/image/upload/v1613674163/league/zvha8z8hwj8mbvyprmii.pdf"} target="_blank">life improvement plans</Link> offered to them which includes joint replacement benefits that they 
                    will most definitely need later in life, 5 more years of extended health insurance coverage, as well as a pension plan, and a long term care coverage plan that you must apply and be approved 
                    for.<br/><br/> 

                    Assuming Damar Hamlin never plays in the NFL again, which seems quite likely considering he suffered from cardiac arrest, he will have to pay for his own health insurance, he has no pension 
                    plan available to him whatsoever, and there is no long term health care coverage plan to apply for. He’s a non-vested player so he could go kick rocks.<br/><br/>

                    <span>▪️▪️▪️</span><br/>

                    Realistically, Bills ownership will probably make an exception and provide him with the extensive health coverage he will most definitely need for the rest of his life and pay out the entirety 
                    of his non-guaranteed contract. I’m sure they conducted a cost/benefit analysis and deduced the backlash they would get from leaving a player who literally almost died for them on the field 
                    uninsured and totally alone to fend for himself in this predatory hellscape we call the American healthcare system would be too costly for their bottom line and thus, will give him the proper 
                    care he needs for the rest of his life.<br/><br/>  

                    However, should it take almost dying on the field to be given long term healthcare and a pension plan? Should players rely on the benevolence of greedy soulless billionaires to make sure they 
                    get what’s owed to them?<br/><br/> 

                    The NFL loves to posture as an organization that cares about the wellbeing of their players, but their actions and policies tell us they couldn’t care less about that. The bottom line is what 
                    they really care about.<br/><br/> 
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default DamarHamlinNFL;