import React from "react";
import { Link } from "react-router-dom";
import { NavStyled } from "../styles/NavStyles";
import { motion } from "framer-motion";

const Navbar = () => {
    return (
        <NavStyled>
            <Link className="nav-link" to={"/"} style={{ textDecoration: 'none' }}>
                <motion.h1
                    whileHover={{
                        color: '#E85454',
                        transition: { duration: 0.5 },
                        skewX: 6,
                    }}
                >
                    Persnickety
                </motion.h1>
            </Link>
        </NavStyled>
    )
}

export default Navbar;