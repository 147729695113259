import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer } from "../styles/ArticleStyles";
import { CenterContentContainer } from "../styles/FunctionalStyles";

const AOCEndorsesWho = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385590/samples/persnickety/aoc_zbijko.jpg" alt="AOC Endorses Who?" />
            {/* </ArticleImageContainer> */}
            <Title>AOC Endorses Who?</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>July 14th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    The AOC of yesteryear and the AOC of today seem so far removed from each other. She’s come a long way from her humble beginnings on the campaign trail, going door to door, hanging signs 
                    everywhere, funded completely on small dollar donations, letting the people know there was an alternative to the status quo establishment Democrat Joe Crowley.<br/><br/> 

                    Her victory against a 20 year incumbent with all of the big money support was nothing short of amazing. It seemed like we were getting a fighter ready and willing to legislate for the people.<br/><br/>  

                    Unfortunately, she has gone along to get along instead of fighting for her constituents. Watching her on Pod Save America was the straw that broke the camel’s back for me. 
                    You can watch the full episode below, but the part that sent me over the edge was her endorsement of Joe Biden.

                    <CenterContentContainer>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/y0JLzuJl7Ps?start=3302" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </CenterContentContainer>

                    Obviously, Biden is going to be the Democratic nominee. He is an incumbent president. They literally never lose their primary, if they’re even challenged that is. The problem is, he’s got 
                    challengers, and one of these challengers, Marianne Williamson, is <Link className="article-link" to={"https://marianne2024.com/"} target="_blank">running on a platform</Link> that AOC is ideologically aligned with, Medicare for all, a living wage, and the right to affordable 
                    housing among other things. Joe Biden doesn’t align with any of that. He is a status quo manager through and through. Can you make the argument that he has been the most progressive president 
                    of the 21st century? Sure you can, but that’s like being the tallest kid in kindergarten. He still primarily serves powerful monied interests and does the bidding of corporations and not the 
                    people. Yet, AOC has given her full support to this man.<br/><br/>

                    She is one of the most popular politicians in the country, specifically with young people, a demographic Joe Biden has no popular support from. If she wasn’t going to throw her support behind 
                    the person she is ideologically aligned with, then why didn’t she at least try to extract some policy concessions from the president for her valuable nomination?! Lowering all prescription 
                    drug prices, raising the federal minimum wage, legalizing marijuana nationally, freeing all of the nonviolent drug offenders. These are all issues she claims to support, yet there is no fight 
                    for them when the opportunity to do so presents itself.<br/><br/>  

                    Instead, she babbles about how Joe Biden has actually been amazing and we should all rally behind our dear leader. Pathetic.<br/><br/> 

                    Cortez said once that she’d rather be a one term representative and get a lot done than serve her whole life as a representative and play the Washington swamp creature games. Seems like it was 
                    all talk.<br/><br/>

                    It’s been death by a thousand cuts with the firebrand congresswoman. Changing her vote from <Link className="article-link" to={"https://slate.com/news-and-politics/2021/09/aoc-apologizes-present-vote-iron-dome-funding.html"} target="_blank">no to present</Link> on a bill to fund Israel’s Iron Dome missile defense system left quite a bitter taste 
                    in the mouths of those who thought her stance on the funding of violent regimes overseas was ironclad. <Link className="article-link" to={"https://www.independent.co.uk/news/world/americas/us-politics/amazon-union-alexandria-ocasio-cortez-b2051582.html"} target="_blank">Abandoning</Link> the Amazon Labor Union after promising to speak at an event during their fight 
                    against the e-commerce giant, claiming security and scheduling conflicts, and then jumping on the bandwagon after they won their election. Yet she had no scheduling conflicts with her <Link className="article-link" to={"https://www.cnn.com/style/article/aoc-met-gala-alexandria-ocasio-cortez-dress/index.html"} target="_blank">Met Gala appearance</Link> around
                    the same time. <Link className="article-link" to={"https://www.politico.com/news/2021/04/02/alexandria-ocasio-cortez-campaign-contributions-478943"} target="_blank">Sending campaign contributions</Link> unsolicited to milk toast centrist Democrats that didn’t even want her money. Her missteps have been adding up and it’s 
                    becoming a real problem.<br/><br/>

                    AOC has accomplished great things during her time in office, it's not like she came in and started legislating like Nancy Pelosi. However, more often than not she has come to disappoint me and the people who thought she’d fight for the working class tooth and nail. 
                    It truly breaks my heart to say this but she’s erring on the side of becoming a Washington swamp creature.<br/><br/> 
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default AOCEndorsesWho;