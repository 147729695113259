import styled from 'styled-components';
import {motion} from 'framer-motion';

export const ArticleStyle = styled(motion.div)`
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    /* flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: auto; */
    /* @media (max-width: 890px){
        width: 100%;
    }
    @media (min-width: 1420px){
        width: 90%;
        margin: auto;
    } */
`;

export const ArticleImage = styled(motion.img)`
    //margin: 1rem;
    margin: auto;
    text-align: center;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    border-radius: 15px;
    
    @media (min-width: 1420px){
        width: 40%;
    }
`;

export const Title = styled(motion.h1)`
    text-align: center;
    font-family: 'Anton', sans-serif;
    font-size: 4rem;
    @media (max-width: 890px){
        font-size: 4rem;
    }
`;

export const Subhead = styled(motion.p)`
    text-align: center;
    font-size: 1.75rem;
    
    @media (max-width: 890px){
        font-size: 1.25rem;
    }
`;

export const NameAndDate = styled(motion.div)`
    margin: 1rem;
    text-align: center;
`;

export const Paragraph = styled(motion.p)`
    color: black;
    font-size: 1.5rem;
    margin: 1.5rem 5rem;
    .article-link{
        color: #E85454;
    }
    @media (max-width: 890px){
        font-size: 1.25rem;
        margin: 1.5rem 0rem;
    }
`;

export const ArticleContainer = styled(motion.div)`
    border-left: 1px solid;
    border-right: 1px solid;
    width: 50%;
    margin: auto;
    margin-top: 1rem;
    //display: flex;
    /* justify-content: center;
    align-items: center; */
    span{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    @media (max-width: 890px){
        width: 90%;
        border: 0px;
    }
    /* @media (max-width: 890px){
        width: 100%;
    }
    @media (min-width: 1420px){
        width: 80%;
    } */
`;

export const QuotedContent = styled(motion.div)`
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 1rem;
    background: #A5A2A6;
    font-size: 1.25rem;
    opacity: 75%;
    width: 75%;
    margin: auto;
    .article-link{
        color: #E85454;
    }
`;

export const ParagraphImage = styled(motion.img)`
    //margin: 1rem;
    margin: auto;
    text-align: center;
    overflow-x: hidden;
    width: 100%;
    height: auto;
    border-radius: 15px;

    @media (min-width: 1420px){
        width: 80%;
    }
`;