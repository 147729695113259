import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import GlobalStyle from "./components/GlobalStyle"

import Navbar from './components/Navbar'
import Footer from './components/Footer';
import Home from './components/Home'
import PayRunningBacks from './articles/PayRunningBacks'
import UFCFightersRippedOff from './articles/UFCFightersRippedOff';
import IfOnlyTheSkyWereTheLimit from './articles/IfOnlyTheSkyWereTheLimit';
import DamarHamlinNFL from './articles/DamarHamlinNFL';
import AOCEndorsesWho from './articles/AOCEndorsesWho';
import CaseForBiden from './articles/CaseForBiden';
import WhiteCollarCrimeStrikesAgain from './articles/WhiteCollarCrimeStrikesAgain';
import RegularPeopleClimateChange from './articles/RegularPeopleClimateChange';
import SportswashingSaudiArabia from './articles/SportswashingSaudiArabia';
import About from './About';
import GunsAreBad from './articles/GunsAreBad';

function App() {

  const location = useLocation();

  return (
    <>
      <GlobalStyle />
      <Navbar />
      <AnimatePresence 
        mode='wait'
        onExitComplete={() => {
          window.scrollTo(0, 0);
        }}
      >
        <Routes location={location} key={location.pathname}>
          <Route path='/' exact element={<Home />} />
          <Route path='/for-the-love-of-god-pay-running-backs-more-please' exact element={<PayRunningBacks />} />
          <Route path='/ufc-fighters-are-getting-ripped-off' exact element={<UFCFightersRippedOff />} />
          <Route path='/if-only-the-sky-were-the-limit' exact element={<IfOnlyTheSkyWereTheLimit />} />
          <Route path='/the-nfl-doesnt-care-about-player-health-&-safety' exact element={<DamarHamlinNFL />} />
          <Route path='/aoc-endorses-who' exact element={<AOCEndorsesWho />} />
          <Route path='/the-begrudging-case-for-biden-in-2024' exact element={<CaseForBiden />} />
          <Route path='/white-collar-crime-strikes-again' exact element={<WhiteCollarCrimeStrikesAgain />} />
          <Route path='/regular-people-are-not-to-blame-for-climate-change' exact element={<RegularPeopleClimateChange />} />
          <Route path='/saudi-arabia-is-trying-and-succeeding-at-sportswashing' exact element={<SportswashingSaudiArabia />} />
          <Route path='/guns-are-bad' exact element={<GunsAreBad />} />
          <Route path='/about' exact element={<About />} />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

export default App;
