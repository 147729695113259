import styled from 'styled-components';
import {motion} from 'framer-motion';

export const HomeStyled = styled(motion.div)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    /* div{
        margin: 3rem;
    } */
`;

export const HomeContainer = styled(motion.div)`
    width: 25%;
    margin: 2rem;
    a{
        text-decoration: none;
        color: black;
    }
    h2, h4{
        color: black;
        text-align: center;
    }
    img{
        width: 100%;
        height: auto;
        border-radius: 15px;
    }
    @media (max-width: 1265px){
        width: 40%;
    }
    @media (max-width: 915px){
        width: 100%;
        h2, h4{
            margin: 1rem;
        }
        h2{
            font-size: 2.25rem;
        }
        h4{
            font-size: 1.5rem;
        }
    }
`;

export const ImgContainer = styled(motion.div)`
    img{
        width: 100%;
        height: auto;
    }
    
`;

// export const HomePageGridStyle = styled(motion.div)`
//     margin: auto;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;
//     h2,
//     h3,
//     h4 {
//         padding-top: 1rem;
//     }
//     h2 {
//         font-family: 'Anton', sans-serif;
//         text-align: center;
//     }
//     a{
//         text-decoration: none;
//         color: black;
//     }
// `

// export const HomeStyle = styled(motion.div)`
//     display: grid;
//     justify-items: center;
//     grid-template-columns: 1fr 2fr 1fr;
//     grid-template-rows: 25rem 30rem;
//     grid-gap: 1rem;
//     width: 80%;
//     margin: auto;
//     padding-top: 0.5rem;
//     .box1 {
//         grid-row: 1/3;
//         grid-column: 2/3;
//         padding: 1rem;
//         border-style: solid;
//         border-width: 0px 1px 0px 1px; 
//     }
//     .box2 {
//         grid-row: 1/2;
//         grid-column: 1/2;
//         padding-bottom: 2rem;
//         border-style: solid;
//         border-width: 0px 0px 1px 0px; 
//         margin: 1rem;  
//     }
//     .box3 {
//         grid-row: 1/2;
//         grid-column: 3/4;
//         padding-bottom: 2rem;
//         border-style: solid;
//         border-width: 0px 0px 1px 0px;
//         margin: 1rem; 
//     }
//     .box4 {
//         grid-row: 2/3;
//         grid-column: 1/2;
//         margin: 1rem;
//     }
//     .box5 {
//         grid-row: 2/3;
//         grid-column: 3/4;
//         margin: 1rem; 
//     }
//     @media (max-width: 890px){
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         .box1,
//         .box2,
//         .box3{
//             border: none;
//         }
//     }
// `;