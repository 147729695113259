import { createGlobalStyle } from "styled-components";
// THE FONTS
// font-family: 'Anton', sans-serif;
// font-family: 'Bebas Neue', sans-serif;
// font-family: 'Poppins', sans-serif;
// complementary color : #E8DFDF

const GlobalStyle = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    body{
        background: #E8DFDF;
        overflow-x: hidden;
        font-family: 'Poppins', sans-serif;
    }
`

export default GlobalStyle;