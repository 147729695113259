import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer } from "../styles/ArticleStyles";

const WhiteCollarCrimeStrikesAgain = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689879210/samples/persnickety/white-collar-crime_n7qkpk.jpg" alt="White Collar Crime Strikes Again" />
            {/* </ArticleImageContainer> */}
            <Title>White Collar Crime Strikes Again</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>July 20th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    Picture this.<br/><br/>

                    You’re down on your luck. Money has never been tighter for you and your family. Maybe you have a kid or two. You just got fired from your job and kicked off your health insurance plan. If 
                    Little Bobby needs to go to the ER, that’s gonna cost your entire life savings if you’re lucky. Unfortunately, you decide that you need to rob a bank in order to get by at the current moment. 
                    You’re certainly not proud of it but you’ve made up your mind because Robin Hood did it once or something. Unsurprisingly, you get caught. Because of your actions, you have to give all of the 
                    money back and then some, and spend 20 years in prison as you watch your family grow and fend for themselves in this cruel world all behind bars.<br/><br/> 

                    Let me paint you another picture dear reader.<br/><br/> 

                    You’re a soulless executive at Bank of America. You’re sitting in your ivory tower, hand fed grapes as you’re fanned by endangered bird feathers, laying down on your leopard skin sofa doing 
                    no real work whatsoever. Until all of a sudden BOOM! Lightning strikes in your mind like Buddha under the fig tree. Except this epiphany isn’t about the meaning of life. It’s about defrauding 
                    innocent hardworking customers by stealing their money so now your mega yacht can have its own little mini yacht to accompany it. Yay! You bark orders at your hapless employees, commanding 
                    them to open accounts in people’s names without their permission and double charge people on fees. Ugh, what a brilliant scam, why didn’t you come up with this idea and yell it at your 
                    underlings sooner? It’s no matter though, soon the fraud will kickstart into motion. Your employees will have the blood on their hands and you will have the benjamins in yours. By the time 
                    the government is in on your scheme, the money will have been made, and the fines that will result from this disgusting plot won’t be greater than the profits made from all the stealing. If 
                    only meemaw could see how great of a businessman you grew up to be, oh what a shame!<br/><br/>

                    Stealing is bad. I think we can all agree on that. There should be consequences for doing so, no matter what your life circumstances may be. When someone robs a bank, the government doesn’t 
                    say “Hey, that’s not nice, give us back half the money you jerkface!”. They get all of the money back and lock you in a cage and rightfully so. However, when the bank robs its own customers, 
                    the government isn’t so ruthless when it comes to punishing them.<br/><br/>

                    The second scenario I layed out actually happened. Last week, Bank of America was <Link className="article-link" to={"https://www.npr.org/2023/07/11/1187060652/bank-of-america-250-million-illegal-fees-fake-accounts-fines#:~:text=Newsletters-,Bank%20of%20America%20to%20pay%20%24250%20million%20for%20illegal%20fees,credit%20cards%20without%20customers%20knowledge."} target="_blank">ordered to pay $250 million dollars</Link> in fines and penalties for defrauding their customers. An investigation 
                    conducted by the Consumer Financial Protection Bureau found that Bank of America targeted hundreds of thousands of customers across multiple different product lines over several years.<br/><br/>

                    They raked in millions upon millions of dollars alone charging customers multiple times on the same transaction for overdraft fees, which are fees you incur when you take out too much money 
                    than you have in your account. These greedy hobgoblins were stealing money from people who didn’t even have it. They committed identity fraud and opened accounts in customer’s names without 
                    their knowledge or permission and racked up fees on these ghost accounts while simultaneously ruining the person’s credit score. They withheld credit card cash and point rewards that were 
                    owed. Truly terrible stuff.<br/><br/>

                    The consequences for robbing their customers and ruining their lives? A $250 million dollar slap on the wrist and 0 jail time for any of the executives who gave the orders to commit these 
                    disgusting acts. Bank of America <Link className="article-link" to={"https://www.statista.com/statistics/249610/bank-of-america-net-income/"} target="_blank">made $27.53 billion dollars</Link> in profit last year. $250 million is an accounting error.<br/><br/> 

                    This isn’t the first time Bank of America has been caught screwing over their customers, and it most certainly won’t be the last. Just last year, they were <Link className="article-link" to={"https://www.consumerfinance.gov/about-us/newsroom/federal-regulators-fine-bank-of-america-225-million-over-botched-disbursement-of-state-unemployment-benefits-at-height-of-pandemic/"} target="_blank">slapped</Link> with a $225 million dollar 
                    fine for unlawfully freezing people’s accounts which led to many people not getting unemployment benefits at the height of the pandemic. In 2014, they were <Link className="article-link" to={"https://www.consumerfinance.gov/about-us/newsroom/cfpb-orders-bank-of-america-to-pay-727-million-in-consumer-relief-for-illegal-credit-card-practices/"} target="_blank">ordered</Link> to pay $727 million dollars 
                    to victims over illegal credit card practices.<br/><br/>
                    
                    If I were a betting man, I’d say they made more than $250 million defrauding innocent Americans for years. Banks are incentivized to commit crimes. They make a profit doing so. The government 
                    isn’t going to force them to pay tenfold for their actions and no one at the top will suffer any consequence whatsoever.<br/><br/>

                    When a regular person steals, they get the book thrown at them. When an empty suit with a white collar working at a “respectable” institution commits the same exact crime, they get to keep 
                    their spoils and don’t even sniff a jail cell.<br/><br/>

                    This is a two tiered justice system we live in folks. Cases like these make it quite clear how our government treats similar crimes committed by different classes of people. White collar 
                    crime should be treated like regular crime. It happens more often than you think and it negatively affects millions upon millions of people who, most of the time, are just regular Joes and 
                    Janes just trying to get by. Executives should go to prison when they steal from their significantly less wealthy customers. This isn’t a controversial stance.<br/><br/> 

                    Be tough on crimes that are crimes, no matter who commits them, plain and simple.<br/><br/>

                    It’s a crime when less fortunate individuals steal from a bank. It’s a crime when extremely wealthy executives at a bank steal from normal people. Both groups deserve to be locked up for 
                    their actions, yet only one of them does. While the other is allowed to profit off of their criminal behavior and live like kings.<br/><br/> 
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default WhiteCollarCrimeStrikesAgain;