import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer } from "../styles/ArticleStyles";

const UFCFightersRippedOff = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385938/samples/persnickety/ngannou_ecrwsx.jpg" alt="UFC Fighters are Getting Ripped Off" />
            {/* </ArticleImageContainer> */}
            <Title>UFC Fighters are Getting Ripped Off</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>May 25th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    The departure of Francis Ngannou from the UFC Heavyweight division and the signing of his historic deal with the PFL has been received with mixed signals, most notably from the daddy of the 
                    UFC, Dana White, who had some <Link className="article-link" to={"https://www.mmafighting.com/2023/5/21/23731580/dana-white-rips-into-francis-ngannou-pfl-over-historic-deal-it-makes-no-sense-to-me"} target="_blank">choice words</Link> to say about the whole ordeal. If you’re a fan of seeing fighters get their fair share of the fighting pie however, then you will undoubtedly be a 
                    fan of Ngannou and what he did.<br/><br/>

                    In his historic deal with the Professional Fighters League, along with getting paid millions of dollars per fight, he secured a $2 million dollar minimum salary for his opponents, equity in the PFL, a role on the PFL 
                    board of directors as an athlete advocate, and is the new chairman for PFL Africa. He now also has the freedom to secure his own sponsors and pursue and negotiate his own deals for boxing 
                    fights, none of which were options with the UFC. This kind of deal shows us the biggest fight mixed martial artists have isn’t with their fellow fighters. The more people realize that and 
                    collectively push for better treatment and compensation for their labor as Ngannou did, the better it will be for fighters all across the globe in their everyday lives.<br/><br/>

                    You may think fighters in the UFC are some of the highest paid athletes in sports. I mean have you ever heard of a man named Conor McGregor? Did you see how much the UFC sold for in 2016? It 
                    was <Link className="article-link" to={"https://www.espn.com/mma/story/_/id/16970360/ufc-sold-unprecedented-4-billion-dana-white-confirms"} target="_blank">$4 billion dollars</Link> by the way if you didn’t see. Clearly they all make crazy ungodly amounts of money, right? Right!? Think again my friend, the people who get paid to get punched, kicked, 
                    and thrown around in an octagon are actually severely underpaid. Actually, the people who don’t go into the cage and put their lives on the line to entertain the millions of fans watching are 
                    the ones who get the lion's share of the profit. Ari Emmanuel & Patrick Whitesell, executives at Endeavor Holdings who own the UFC, had a <Link className="article-link" to={"https://bloodyelbow.com/2022/04/01/endeavor-ufc-owners-emanuel-whitesell-308-million-pay-packages-2021-mma-finances-news/"} target="_blank">compensation package valued at $431 million dollars</Link> in 
                    2021. In the same year, the <Link className="article-link" to={"https://thesportsdaily.com/news/2021-ufc-fighter-salaries-complete-list-fox11/"} target="_blank">entire UFC roster</Link> made only $96,813,420 million dollars in total income from their fights. That’s across 605 people. The highest individual earner was Connor McGregor 
                    with $10 million. Without those 605 people, there is no product for the likes of Ari Emmanuel, Patrick Whitesell, and Dana White to sell and I don’t think people would shell out $60 to see those 
                    clowns fight in an octagon.<br/><br/>

                    Now if that doesn’t grind your gears, then the way fighter pay is structured in the UFC most definitely will. UFC fighters are treated as independent contractors and not employees of the company 
                    and thus have fewer protections under the law as a worker. Their right to organize a union isn't guaranteed, medical benefits are only offered for immediate injuries as a result of a fight or 
                    training camp, unemployment benefits don’t exist if you are fired, the list goes on and on.<br/><br/>

                    That’s just the half of it. When it comes to pay, there are three tiers in the UFC and all fighters sign a fixed contract agreeing to a fixed amount of money per fight. The lowest is where 
                    most fighters start off at, ranging anywhere from $10,000 - $30,000 per bout. Assuming you stay healthy for the entire year, if you fight 3 times a year, at the high end, you’re taking home 
                    $60,000. This is before you account for taxes, coaching fees, gym fees, management fees, pre-fight medical exams (which fighters are responsible to pay for, not the UFC), and other 
                    miscellaneous expenses. For comparison, the <Link className="article-link" to={"https://en.as.com/nba/who-is-the-lowest-paid-nba-player-in-the-2022-2023-season-n/"} target="_blank">lowest paid NBA player</Link>, Ishmail Wainright, made $633,891 this year. That’s more than what 97% of the entire UFC roster made in individual earnings 
                    in 2021. And he gets health benefits. Ishmail just dribbles a basketball. Fighters lock themselves inside a cage with another human being and beat the snot out of each other.<br/><br/> 

                    To add salt to the wound (which the UFC definitely won’t cover), the wage share for fighters in <Link className="article-link" to={"https://bloodyelbow.com/2023/03/07/ufc-admits-to-lower-athlete-costs-as-they-boast-best-financial-year-in-2022/"} target="_blank">2021 was 17.5%</Link> of the revenue, which has decreased from previous years where it used to be at a <Link className="article-link" to={"https://www.forbes.com/sites/paulgift/2022/04/19/ufc-fighter-wage-share-held-steady-at-19-20-for-11-straight-years/?sh=5d01f697b106"} target="_blank">whopping 19-20%</Link> 
                    . Not to mention, in 2022, the company <Link className="article-link" to={"https://www.mmafighting.com/2023/2/28/23619576/ufc-generated-record-revenues-in-2022-including-the-best-sponsorship-year-ever"} target="_blank">boasted</Link> about having its best financial year ever in its entire history of existence. Yet, there are still fighters on their roster who 
                    are at or below the poverty line. Now, if you’re reading this and you’re still not angry, then you’re definitely Dana White in which case, PAY YOUR FIGHTERS MORE DANA (and also stop <Link className="article-link" to={"https://www.tmz.com/2023/01/02/dana-white-wife-drunk-fight-slap-new-years-eve-nightclub-cabo/"} target="_blank">hitting 
                    your wife</Link>)! You shouldn’t have to fight out your initial wage slave contract to have a chance at making a decent living.<br/><br/> 

                    This is the crux of Ngannou’s UFC departure and lucrative signing with the PFL, the profits have only been growing for the UFC, but the overwhelming majority of those profits do not end up in 
                    the hands of the people who make those profits possible with their hard and grueling labor, it goes to the suits.<br/><br/> 
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default UFCFightersRippedOff;