import { motion } from "framer-motion";
import styled from 'styled-components';
import { pageAnimation } from "./animation";

const About = () => {
    return(
        <StyledAbout
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            <motion.h1>About Persnickety</motion.h1>
            <StyledParagraph>
                <motion.p>
                    Persnickety is a sports and politics blog through the lens of a normal American, striving to be a watchdog of the powerful through thoughtful and adversarial writing. I aim to give
                    readers a fresh perspective on topics ranging anywhere from healthcare to corruption to foreign policy and more. This blog came to be as a passion project meant to give a bullhorn 
                    to the voice of the working class, a voice often neglected and drowned out. The framing of discussions in mainstream media bends towards the powerful and monied interests. The goal is to
                    reframe the conversation and try to give readers a new point of view they might not have come across all that much.
                </motion.p>
            </StyledParagraph>
            <motion.h1>Submissions</motion.h1>
            <StyledParagraph>
                <motion.p>
                    If you have something to say about the world of politics or sports and think Persnickety is a good place to publish your piece, email <span>persnicketysubmissions@gmail.com</span> and make
                    sure to include the topic you are writing about in the subject and an abstract of your work in the body. Persnickety woud love to hear from  Thank you.
                </motion.p>
            </StyledParagraph>
        </StyledAbout>  
    )
}

const StyledAbout = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const StyledParagraph = styled(motion.div)`
    width: 50%;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-size: 1.6rem;
    span{
        color: #E85454;
    }
    @media (max-width: 890px){
        width: 100%;
        padding: 2rem;
        font-size: 1.25rem;
    }
`;

export default About;