import React from "react";
import { pageAnimation } from "../animation";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { ImgContainer, HomeStyled, HomeContainer } from "../styles/HomePageStyles";

const Home = () => {
    return (
        // <HomeStyle
        //     variants={pageAnimation} 
        //     initial="hidden" 
        //     exit="exit" 
        //     animate="show"
        // >
        //     <HomePageGridStyle className="box1">
        //         <ImgContainer>
        //             <Link to={"/aoc-endorses-who"}>
        //                 <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385590/samples/persnickety/aoc_zbijko.jpg" alt="AOC Endorses Who?" />
        //             </Link>
        //         </ImgContainer>
        //         <Link to={"/aoc-endorses-who"}>
        //             <motion.h2
        //                 whileHover={{
        //                     color: '#E85454',
        //                     transition: { duration: 0.5 },
        //                     skewX: 6,
        //                 }}
        //             >
        //                 AOC Endorses Who?
        //             </motion.h2>
        //         </Link>
        //         <h4><i>by Shenal Tissera</i></h4>
        //     </HomePageGridStyle>

        //     <HomePageGridStyle className="box2">
        //         <ImgContainer>
        //             <Link to={"/for-the-love-of-god-pay-running-backs-more-please"}>
        //                 <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385942/samples/persnickety/saquon_x7govy.jpg" alt="For the Love of God Pay Running Backs More Please"/>
        //             </Link>                    
        //         </ImgContainer>
        //         <Link to={"/for-the-love-of-god-pay-running-backs-more-please"}>
        //             <motion.h2
        //                 whileHover={{
        //                     color: '#E85454',
        //                     transition: { duration: 0.5 },
        //                     skewX: 6,
        //                 }}
        //             >
        //                 For the Love of God Pay Running Backs More Please
        //             </motion.h2>
        //         </Link>
        //         <h4><i>by Shenal Tissera</i></h4>
        //     </HomePageGridStyle>

        //     <HomePageGridStyle className="box3">
        //         <ImgContainer>
        //             <Link to={"/ufc-fighters-are-getting-ripped-off"}>
        //                 <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385938/samples/persnickety/ngannou_ecrwsx.jpg" alt="UFC Fighters are Getting Ripped Off"/>
        //             </Link>
        //         </ImgContainer>
        //         <Link to={"/ufc-fighters-are-getting-ripped-off"}>
        //             <motion.h2
        //                 whileHover={{
        //                     color: '#E85454',
        //                     transition: { duration: 0.5 },
        //                     skewX: 6,
        //                 }}
        //             >
        //                 UFC Fighters are Getting Ripped Off
        //             </motion.h2>
        //         </Link>
        //         <h4><i>by Shenal Tissera</i></h4>
        //     </HomePageGridStyle>

        //     <HomePageGridStyle className="box4">
        //         <ImgContainer>
        //             <Link to={'/if-only-the-sky-were-the-limit'}>
        //                 <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689387255/samples/persnickety/sportsMoney_hzbog7.webp" alt="If Only the Sky were the Limit" />                    
        //             </Link>
        //         </ImgContainer>
        //         <Link to={"/if-only-the-sky-were-the-limit"}>
        //             <motion.h2
        //                 whileHover={{
        //                     color: '#E85454',
        //                     transition: { duration: 0.5 },
        //                     skewX: 6,
        //                 }}
        //             >
        //                 If Only the Sky Were the Limit
        //             </motion.h2>
        //         </Link>
        //         <h4><i>by Shenal Tissera</i></h4>
        //     </HomePageGridStyle>

        //     <HomePageGridStyle className="box5">
        //         <ImgContainer>
        //             <Link to={'/the-nfl-doesnt-care-about-player-health-&-safety'}>
        //                 <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385928/samples/persnickety/nflHealth_i1nn7i.jpg" alt="The NFL Doesn't Care About Player Health & Safety" />
        //             </Link>
        //         </ImgContainer>
        //         <Link to={'/the-nfl-doesnt-care-about-player-health-&-safety'}>
        //             <motion.h2
        //                 whileHover={{
        //                     color: '#E85454',
        //                     transition: { duration: 0.5 },
        //                     skewX: 6,
        //                 }}
        //             >
        //                 The NFL Doesn't Care About Player Health & Safety
        //             </motion.h2>
        //         </Link>
        //         <h4><i>by Shenal Tissera</i></h4>                
        //     </HomePageGridStyle>           
        // </HomeStyle>

        <HomeStyled
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/regular-people-are-not-to-blame-for-climate-change"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1690330845/samples/persnickety/climateApocalypse_mucte2.jpg" alt="Regular People are Not to Blame for Climate Change" />
                    </Link>
                </ImgContainer>
                <Link to={"/regular-people-are-not-to-blame-for-climate-change"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        Regular People are Not to Blame for Climate Change
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/guns-are-bad"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1699204956/samples/persnickety/gunsAreBad_ux6tya.jpg" alt="Guns are Bad" />
                    </Link>
                </ImgContainer>
                <Link to={"/guns-are-bad"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        Guns Are Bad
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/white-collar-crime-strikes-again"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689879210/samples/persnickety/white-collar-crime_n7qkpk.jpg" alt="White Collar Crime Strikes Again" />
                    </Link>
                </ImgContainer>
                <Link to={"/white-collar-crime-strikes-again"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        White Collar Crime Strikes Again
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={'/the-nfl-doesnt-care-about-player-health-&-safety'}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385928/samples/persnickety/nflHealth_i1nn7i.jpg" alt="The NFL Doesn't Care About Player Health & Safety" />
                    </Link>
                </ImgContainer>
                <Link to={'/the-nfl-doesnt-care-about-player-health-&-safety'}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        The NFL Doesn't Care About Player Health & Safety
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={'/the-begrudging-case-for-biden-in-2024'}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689725144/samples/persnickety/bidenIceCream_rnp6dg.jpg" alt="The Begrudging Case for Biden in 2024" />
                    </Link>
                </ImgContainer>
                <Link to={'/the-begrudging-case-for-biden-in-2024'}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        The Begrudging Case for Biden in 2024
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/for-the-love-of-god-pay-running-backs-more-please"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385942/samples/persnickety/saquon_x7govy.jpg" alt="For the Love of God Pay Running Backs More Please" />
                    </Link>
                </ImgContainer>
                <Link to={"/for-the-love-of-god-pay-running-backs-more-please"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        For the Love of God Pay Running Backs More Please
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/saudi-arabia-is-trying-and-succeeding-at-sportswashing"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1691155512/samples/persnickety/sportswashingSaudi_fdt3xf.jpg" alt="Saudi Arabia is Trying and Succeeding at Sportswashing" />
                    </Link>
                </ImgContainer>
                <Link to={"/saudi-arabia-is-trying-and-succeeding-at-sportswashing"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        Saudi Arabia is Trying and Succeeding at Sportswashing
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/aoc-endorses-who"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385590/samples/persnickety/aoc_zbijko.jpg" alt="AOC Endorses Who?" />
                    </Link>
                </ImgContainer>
                <Link to={"/aoc-endorses-who"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        AOC Endorses Who?
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={"/ufc-fighters-are-getting-ripped-off"}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385938/samples/persnickety/ngannou_ecrwsx.jpg" alt="UFC Fighters are Getting Ripped Off" />
                    </Link>
                </ImgContainer>
                <Link to={"/ufc-fighters-are-getting-ripped-off"}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        UFC Fighters are Getting Ripped Off
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            <HomeContainer>
                <ImgContainer
                    whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.5 },
                    }}
                >
                    <Link to={'/if-only-the-sky-were-the-limit'}>
                        <img src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689387255/samples/persnickety/sportsMoney_hzbog7.webp" alt="If Only the Sky were the Limit" />
                    </Link>
                </ImgContainer>
                <Link to={'/if-only-the-sky-were-the-limit'}>
                    <motion.h2
                        whileHover={{
                            color: '#E85454',
                            transition: { duration: 0.5 },
                            skewX: 6,
                        }}
                    >
                        If Only the Sky Were the Limit
                    </motion.h2>
                </Link>
                <h4><i>by Shenal Tissera</i></h4>
            </HomeContainer>
            
        </HomeStyled>
    );
};

export default Home;