import React from "react";
import { pageAnimation } from "../animation";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer } from "../styles/ArticleStyles";

const IfOnlyTheSkyWereTheLimit = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689387255/samples/persnickety/sportsMoney_hzbog7.webp" alt="If Only the Sky were the Limit" />
            {/* </ArticleImageContainer> */}
            <Title>If Only the Sky Were the Limit</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>February 3rd, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    It took me a while to understand what the salary cap in professional sports was. As a child, it was just that thing in 2K that prevented me from having Lebron James, Kobe Bryant, Shaq, Tim 
                    Duncan, and Dwyane Wade all on the same team. So annoying! As I got older though, I found some clarity.<br/><br/> 

                    I mean it says it right in the name, it’s a cap on the amount teams can spend on player’s salaries. Athletes can only make so much money until their billionaire owners say no no no, enough 
                    money for you. Although, there is a good argument for capping the total wages spent on the people who provide all of the entertainment and value for their team and league, which is preventing 
                    the wacky scenario I just laid out above.<br/><br/> 

                    If you were a star athlete and there was no limit on what teams could spend on you, would you rather go to the small market team in Cleveland, or would you go to a large market team with 
                    deeper pockets in sunny Los Angeles or Miami?<br/><br/>

                    The salary cap does bring much needed parity within sports leagues so that we don’t see large and popular cities like Los Angeles and Boston accumulate all of the talent and make it into the 
                    championship matches every year. As a lifelong sports fan, that is the last thing I want to see happen on this planet.<br/><br/>

                    However, the other argument people bring up, limiting the cost and financial risk owners take on, is quite humorous to me. Isn’t risk the name of the game in capitalism? If a billionaire can’t 
                    handle the risk and cost of owning and managing a sports franchise, then maybe he should pull himself up by his bootstraps and work a little harder.<br/><br/>    

                    Also, they completely ignore the fact that there is immense risk and cost in being an athlete. Majority of athletes don’t come from insanely wealthy and privileged backgrounds like most, if 
                    not all of their owners do. In order to get to the pinnacle of their respective sport, they had to dedicate their entire life towards training and improving their skills with hopes that one 
                    day, the big leagues come knocking. They don’t have time to cultivate another tangible skill whilst they're in college because they already have a full time job being an athlete that 
                    entertains the masses for free.<br/><br/> 

                    If they are talented and lucky enough to be given the opportunity to become a professional athlete, then all of the pain, suffering, and sacrifice wasn’t all for nothing. With only so many 
                    spots on a professional team though, there are a lot of people that come up short and all of that pain, suffering, and sacrifice was all for nothing and they’re stuck in this no man’s land of 
                    not being good enough to be a professional athlete and not having any other real skills to use for employment elsewhere.<br/><br/>  

                    Not to mention the physical risk they face every time they step onto the field or court or whatever it may be. Longevity and sports are like water and oil. People only have a small window of 
                    opportunity to make money entertaining the fans until their body tells them it’s time to hang it up.<br/><br/>  

                    It’s quite exploitative to limit the amount of money these people can make and at the same time, their billionaire owners rake it in hand over fist. Especially when you consider the fact that 
                    the athletes do all of the work and are the product that people spend their money to watch and follow. These people should be able to reap a much larger share of the profits which they 
                    generate entirely with their bodies and have much more earnings potential under a salary cap in the finite amount of time they have playing sports.<br/><br/>

                    A cursory glance at the salaries of professional athletes and you might be playing the world’s smallest violin. However, not all athletes are making that kind of generational wealth that the 
                    Lebron James’s and Steph Curry’s of the world are. We don’t have sports leagues without every athlete from the top player all the way to the bottom. All I am asking is that the salary cap in 
                    major league sports be more reflective of the risk that athletes take on rather than the privileged mega wealthy owners.<br/><br/>
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default IfOnlyTheSkyWereTheLimit;