import styled from 'styled-components';
import {motion} from 'framer-motion';

export const NavStyled = styled(motion.div)`
    font-family: 'Bebas Neue', sans-serif;
    text-align: center;
    display: flex;
    margin: auto;
    margin-bottom: 3rem;
    justify-content: center;
    align-items: center;
    padding: 1rem 3rem;
    min-height: 10vh;
    border-bottom: 1px solid;
    h1{
        color: black;
        font-size: 6rem;
    }
    @media (max-width: 890px){
        h1{
            font-size: 4rem;
        }
    }
`