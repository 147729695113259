import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, QuotedContent, ArticleContainer } from "../styles/ArticleStyles";
import { CenterContentContainer, } from "../styles/FunctionalStyles";

const PayRunningBacks = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689385942/samples/persnickety/saquon_x7govy.jpg" alt="For the Love of God Pay Running Backs More Please" />
            
            <Title>For the Love of God Pay Running Backs More Please</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>July 12th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    Running backs holding out of training camp in an attempt to secure a long term deal has become an NFL tradition at this point. This year’s holdout is Saquon Barkley. <br/><br/>

                    The Giants star is coming off his best season yet since his record breaking rookie year. For his herculean efforts this past season, he’s been <Link className="article-link" to={"https://www.cbssports.com/nfl/news/giants-saquon-barkley-considering-week-1-holdout-amid-franchise-tag-drama-disputes-reported-contract-offer/#:~:text=As%20of%20this%20week%2C%20Barkley,the%20NFL%27s%20highest%2Dpaid%20RBs."} target="_blank">given the franchise tag</Link> worth $10.9 million dollars 
                    for 1 year with no long term deal agreed to yet.<br/><br/>

                    The running back position is the most brutal and damaging position you can play in football. You’re either running as fast as you can, getting clobbered and manhandled by significantly larger 
                    stronger men or you have to block significantly larger stronger men as they try to annihilate you and your quarterback.<br/><br/>

                    Because of the extremely violent nature of their position, the average career length for a running back is <Link className="article-link" to={"https://www.statista.com/statistics/240102/average-player-career-length-in-the-national-football-league/" } target="_blank">2.57 years</Link>, the lowest of any position. So you can see why securing a long term deal to 
                    ensure their life is secure for themselves and their family is a top priority for people like Barkley.<br/><br/> 

                    Teams are aware of the fleeting essence of the position and are incentivized to not pay running backs large sums of money. With the exploitative nature of rookie contracts, especially for those 
                    who are drafted in later rounds, teams can get decent running back play at a bargain bin price. They’ll proceed to run them into the ground, and by the time they’re done with their rookie contract, 
                    which netted them at most a couple million dollars, and it’s time to sign the second contract which is when players can finally make some life changing money, there is so much tread on the tires 
                    that teams will treat you like a depreciated asset and toss you aside, rinsing and repeating with another bright eyed kid and/or not offer you a fair contract that pays you your worth. This is all 
                    assuming of course you bucked the trend and lasted more than 2.57 years in the league.<br/><br/> 

                    As a result, players like Austin Ekeler had this to say about the realities of the running back market in today’s NFL:
                </Paragraph>

                    <CenterContentContainer>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/GXyoUSIY720" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </CenterContentContainer>
                    
                    <QuotedContent>
                        “What do we got to do to get paid as running backs to our actual value and not just be getting like you know a few mil when we’re out here, like I said, [I] literally scored 20 touchdowns last 
                        year. 1600 all purpose yards right. Like, and I can’t get an extension? Like, you don’t want me here? Like, wait a minute, am I missing something here?”
                    </QuotedContent>

                <Paragraph>
                    Critics would argue Ekeler shouldn’t complain about getting paid <Link className="article-link" to={"https://www.spotrac.com/nfl/los-angeles-chargers/austin-ekeler-22247/"} target="_blank">$6.25 million dollars</Link> for this upcoming season to play a high risk position that is prone to injury and short career lengths. 
                    Wouldn’t that be an argument that people like Ekeler and Barkley should be paid even more because they play a high risk position that is prone to injury and short career lengths? Especially when 
                    they’re putting up 20 tuddies on the gridiron in a single season (and helping me win my fantasy league).<br/><br/>

                    It seems to me there’s a structural problem with how pay and salary caps work in the NFL. They made <Link className="article-link" to={"https://www.statista.com/statistics/193457/total-league-revenue-of-the-nfl-since-2005/#:~:text=The%20statistic%20depicts%20the%20total,of%2017.19%20billion%20U.S.%20dollars."} target="_blank">$17.91 billion dollars</Link> in 2021. You’re telling me these billionaires are pleading poverty when it comes 
                    to paying running backs more?<br/><br/>

                    Eliminate the 4 year non guaranteed rookie contracts these players are forced to sign and replace them with 2-3 year fully guaranteed deals. Make running backs exempt from the franchise tag or 
                    change the franchise tag amount for running backs to take the average of the top 2 salaries at the position instead of the top 5. Set up a fund based on yardage total and touchdowns that goes 
                    directly to the top producers at each offensive receiving and rushing position that is separate from the team's salary cap so the best at their craft get what’s theirs.<br/><br/> 

                    I’m just throwing stuff at the wall and seeing what sticks but there has got to be a solution that will make sure running backs get paid for the valuable work they do for millions of people like 
                    me to enjoy.<br/><br/>

                    Athletes aren’t commodities that should be squeezed of all value and thrown away and forgotten about forever the moment their bodies stop performing at an elite level. They are people with 
                    families and loved ones to take care of and call me crazy but treating them like such isn’t a lot to ask for.<br/><br/>
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default PayRunningBacks;