import { motion } from "framer-motion";
import { styled } from "styled-components";

export const CenterContentContainer = styled(motion.div)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3rem;
    p{
        text-align: center;
    }
`;

export const Wrapper = styled(motion.div)`
    overflow-x: hidden;
`;