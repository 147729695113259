export const pageAnimation = {
    // Fade In
    hidden: {
        opacity: 0,
        y: 600
    },
    // Fade Out
    show: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 1,
            when: "beforeChildren",
            staggerChildren: 0.5,
        },
    },
    exit: {
        opacity: 0,
        y: 600,
        transition: {
            duration: .6,
        },
    },
};