import styled from "styled-components";
import { motion } from "framer-motion";

export const FooterStyles = styled(motion.footer)`
    min-height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    flex-direction: row;
    color: black;
    border-top: 1px solid;
    margin-top: 2rem;
    h3{
        margin: 0rem 2rem;
    }
    a{
        text-decoration: none;
        color: black;
        font-size: 0.6rem;
    }
`;