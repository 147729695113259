import { FooterStyles } from "../styles/FooterStyles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Footer = () => {
    return(
        <FooterStyles>
            <h3>Persnickety &copy; 2023</h3>
            <Link to={'/about'}>
                <motion.h3
                    whileHover={{
                        color: '#E85454',
                        transition: { duration: 0.5 },
                        skewX: 6,
                    }}
                >
                    About
                </motion.h3>
            </Link>
        </FooterStyles>
    )
}

export default Footer;