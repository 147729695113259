import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer } from "../styles/ArticleStyles";

const SportswashingSaudiArabia = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1691155512/samples/persnickety/sportswashingSaudi_fdt3xf.jpg" alt="Saudi Arabia is Trying and Succeeding at Sportswashing" />
            
            <Title>Saudi Arabia is Trying and Succeeding at Sportswashing</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>August 4th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    Saudi Arabia, land of oil and rampant human rights abuses, is now trying to infiltrate the sporting world. Fresh off the heels of a merger between the PGA Tour and LIV Golf, the golf league 
                    funded entirely by Saudi money through their sovereign wealth fund, Saudi Arabia is now trying to take over soccer.<br/><br/>  

                    Last week, French superstar Kylian Mbappe was offered the <Link className="article-link" to={"https://www.sportingnews.com/us/soccer/news/kylian-mbappe-reject-saudi-arabia-contract-al-hilal-psg/beqcltvpr8kgi02rlddzhvym"} target="_blank">most lucrative</Link> contract for an athlete ever of which he rejected. The deal would’ve netted the soccer icon an eye watering $776 
                    million in potential earnings.<br/><br/>

                    This is an attempt by Saudi Arabia to sportswash their reputation as a theocratic dictatorship that continues to commit <Link className="article-link" to={"https://www.hrw.org/news/2022/04/18/yemen-latest-round-saudi-uae-led-attacks-targets-civilians"} target="_blank">war crimes</Link> in Yemen (using weapons sold and manufactured by the <Link className="article-link" to={"https://www.pbs.org/newshour/politics/u-s-approves-massive-arms-sale-to-saudi-arabia-united-arab-emirates-to-counter-iran"} target="_blank">US</Link>), 
                    of which 377,000 people are dead with <Link className="article-link" to={"https://www.aljazeera.com/news/2021/11/23/un-yemen-recovery-possible-in-one-generation-if-war-stops-now#:~:text=New%20UNDP%20report%20projects%20that,reach%201.3%20million%20by%202030."} target="_blank">70%</Link> of them being children, <Link className="article-link" to={"https://www.bbc.com/news/world-europe-45812399"} target="_blank">dismember</Link> American journalists with no repercussions whatsoever, and <Link className="article-link" to={"https://www.fairplanet.org/story/death-penalty-homosexualty-illegal/"} target="_blank">execute</Link> people for being gay among other executable 
                    offenses. Not to mention that they most definitely <Link className="article-link" to={"https://theintercept.com/2021/09/11/september-11-saudi-arabia/"} target="_blank">funded</Link> 9/11. If they have their way, they’ll make you think of all the wonderful and entertaining sports they provide instead of all of 
                    the horrific atrocities they have committed and will continue to commit, yay!<br/><br/>

                    Right now, money is talking.<br/><br/>

                    After promising its players no deal with the Saudi backed league would ever happen, a deal did indeed happen with the oil devil and the PGA Tour merged with LIV Golf, pending authorization 
                    from the US government.<br/><br/> 

                    Sports leagues cannot compete with the bottomless pit of money Saudi Arabia has at its disposal. In an ideal world, athletes wouldn’t overlook countless human rights abuses and go join a 
                    sports league funded entirely by a bloodthirsty dictatorship for a bigger payday. However, it’s hard to tell an average athlete who wouldn’t otherwise earn the kind of money being offered 
                    anywhere else and even harder to tell a greedy star who’s already making millions that there’s even more millions with his name on it available to him to be a righteous and moral person 
                    and not take the elevated payday from a murderous country such as Saudi Arabia.<br/><br/> 

                    Specifically with LIV Golf, the pay structure for players is much better than the PGA Tour, especially for players finishing towards the bottom of the event as LIV golf players make <Link className="article-link" to={"https://www.sportskeeda.com/golf/liv-golf-salary-how-much-golfers-get-paid"} target="_blank">at minimum</Link> $120,000, whereas
                    PGA Tour members at the bottom of an event stood to make a <Link className="article-link" to={"https://www.easyofficepools.com/pga-tour-payout-percentages-and-projected-earnings/"} target="_blank">third</Link> of that, and made nothing if they didn’t make the cut.<br/><br/> 

                    There were clear problems with the PGA Tour’s pay scale but now golfers are getting paid by a country that is directly involved in and continues to exacerbate the world’s <Link className="article-link" to={"https://www.wfp.org/yemen-crisis"} target="_blank">worst</Link> humanitarian 
                    crisis.<br/><br/>     

                    They’ve already made their moves in the soccer world getting the likes of Christiano Ronaldo, Karim Benzema, and N’Golo Kante among other prominent names to play in their blood money 
                    league. Mbappe would’ve been the biggest signing to date for them as he is in his absolute prime as an athlete whereas everyone else they have signed thus far have reached the tailends of 
                    their careers.<br/><br/>

                    Nevertheless, it is only a matter of time before they lure young money hungry talent with price tags that none of the top European leagues can match, allowing them to field more 
                    competitive teams to lure even more young money hungry talented individuals, draining the other leagues not funded by a genocidal regime of much needed world class players.<br/><br/>

                    They’ve already wrapped their oily tendrils around F1, as they have hosted the Saudi Arabian Grand Prix for the past 3 years and their state oil company, Aramco, is now a <Link className="article-link" to={"https://www.formula1.com/en/toolbar/partners.html"} target="_blank">global partner</Link> of 
                    F1 and a <Link className="article-link" to={"https://sportskhabri.com/aston-martin-sponsors-2023/"} target="_blank">strategic partner</Link> for Aston Martin. On top of that, there were rumors spreading of a $20 billion dollar <Link className="article-link" to={"https://theathletic.com/4324851/2023/03/19/report-of-20-billion-saudi-bid-to-buy-f1-was-speculation-sports-minister/"} target="_blank">bid</Link> by the sovereign wealth fund to purchase F1 entirely but it never 
                    materialized.<br/><br/> 

                    For now at least.<br/><br/>  

                    No sport seems to be safe from the murderous oil giants.<br/><br/>

                    The day they start poaching NBA players is the day I start to build my cabin in the woods and leave society entirely.<br/><br/>    

                    There’s only so much professional sports leagues can do to fend off a competitor with infinite pockets before they are forced to kneel down and kiss the Saudi ring. If Saudi Arabia is 
                    allowed to buy off the golfing industry, it won’t stop there. The Antitrust Division of the Justice Department and the F.T.C. have the <Link className="article-link" to={"https://www.nytimes.com/2023/06/07/sports/golf/pga-liv-golf-merger.html"} target="_blank">power</Link> to block this merger, but I certainly wouldn’t 
                    hold my breath waiting for that to happen.<br/><br/>
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default SportswashingSaudiArabia;