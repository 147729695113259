import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, QuotedContent, ArticleContainer, ParagraphImage } from "../styles/ArticleStyles";
import { CenterContentContainer, } from "../styles/FunctionalStyles";

const RegularPeopleClimateChange = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1690330845/samples/persnickety/climateApocalypse_mucte2.jpg" alt="Regular People are Not to Blame for Climate Change" />
            
            <Title>Regular People are Not to Blame for Climate Change</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>July 25th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    You remember when we were all younger and thought ‘Ehhhhhhhhhhhh climate change isn’t really affecting us now, we don’t need to think about it till later’. Well, later has become now and it’s 
                    time we really start talking about it because Earth’s vital signs are reaching DEFCON 1.<br/><br/>

                    On July 3rd, the global temperature was the hottest ever recorded at <Link className="article-link" to={"https://www.reuters.com/world/world-registers-hottest-day-ever-recorded-july-3-2023-07-04/"} target="_blank">62.62</Link> degrees Fahrenheit. Then the next day and the day after, that record was broken with a global temperature of <Link className="article-link" to={"https://www.weatherandradar.com/weather-news/july-4-was-earths-hottest-day-on-record-according-to-new-data--6367a7dd-70fe-4f16-928e-0084852b6b3a"} target="_blank">62.92</Link> degrees 
                    Fahrenheit, shattering the previous record in 2016 when global temperatures reached 62.46 degrees Fahrenheit. This was right after the <Link className="article-link" to={"https://www.noaa.gov/news/earth-just-had-its-hottest-june-on-record#:~:text=The%20average%20global%20surface%20(land,record%20set%20in%20June%202020."} target="_blank">hottest month of June</Link> ever recorded in human 
                    history concluded, knocking June 2020 off the top spot.<br/><br/>

                    Last week, ocean temperatures in South Florida <Link className="article-link" to={"https://www.cbsnews.com/news/florida-ocean-temperatures-rise-to-the-90s-nearly-hitting-100/"} target="_blank">peaked</Link> at 97 degrees Fahrenheit and oscillated between the low and mid 90’s, becoming the world’s largest hot tub.<br/><br/>  

                    Phoenix set the record for US cities with <Link className="article-link" to={"https://news.azpm.org/p/news-articles/2023/7/18/216808-phoenix-scorches-at-110-for-19th-straight-day-breaking-big-us-city-records-in-global-heat-wave/#:~:text=The%20city%27s%20record%20streak%20of,sweltering%20temperatures%20across%20the%20globe."} target="_blank">19 straight days</Link> of temperatures at 110 degrees Fahrenheit.<br/><br/> 

                    China set its record for the <Link className="article-link" to={"https://www.reuters.com/world/china/china-logs-522-celsius-extreme-weather-rewrites-records-2023-07-17/"} target="_blank">hottest temperature recorded</Link> in their country at 126 degrees Fahrenheit.<br/><br/>

                    These past 8 years have been the <Link className="article-link" to={"https://public.wmo.int/en/media/press-release/past-eight-years-confirmed-be-eight-warmest-record#:~:text=The%20warmest%20eight%20years%20have,contributed%20to%20record%20global%20temperatures."} target="_blank">8 warmest years</Link> on record. This year is heavily trending towards making those past eight years look like winter wonderlands.<br/><br/>

                    It’s not only the sweltering heat we have to worry about. Along with higher highs, man-made climate change also brings along more extreme weather disasters like wildfires and droughts. Just 
                    last month when <Link className="article-link" to={"https://www.cbsnews.com/news/how-did-wildfires-in-canada-start-spread-to-europe-midwest/"} target="_blank">Canadian wildfires</Link> burned through millions of acres as a result of record heat and drought in the region that led to extremely warm and dry conditions which eventually set 
                    Canada ablaze, enveloping large swaths of the US in smoke and causing NYC to have the <Link className="article-link" to={"https://www.rmets.org/metmatters/worst-air-quality-world-wildfire-smog-smothers-new-york#:~:text=The%20AQI%20was%20above%20480,air%20quality%20in%20the%20world."} target="_blank">worst air quality</Link> in the world in early June.
                </Paragraph>

                <CenterContentContainer>
                    <ParagraphImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1690331095/samples/persnickety/nyPollution_xxnsjz.jpg" alt="Regular People are Not to Blame for Climate Change" />
                    <p>New York City covered in a yellow haze from the Canada wildfires on June 6th, 2023</p>
                </CenterContentContainer>

                <Paragraph>
                    This is reality now.<br/><br/> 

                    According to the <Link className="article-link" to={"https://www.ipcc.ch/report/ar6/wg2/resources/press/press-release/"} target="_blank">IPCC</Link>, “To avoid mounting loss of life, biodiversity and infrastructure, ambitious, accelerated action is required to adapt to climate change, at the same time as making 
                    rapid, deep cuts in greenhouse gas emissions.” Before you start hyperventilating about that one time you left all the lights on in the house and now all flamingos are gonna die because of 
                    that, just know that you are not the problem.<br/><br/>

                    Climate change and the solutions needed to curb humanity’s impending doom are framed in a way that heaps all the blame on the average Joe and Jane. If only you consumerist pigs stopped 
                    driving your gas powered cars and ate less beef! The blame should really be on the oil and gas companies and those in power that do the bidding of these companies, allowing the planet and 
                    all of its inhabitants to slowly boil away.<br/><br/>

                    The carbon footprint has been used as a tool to make people feel completely guilty for the state of the planet and the fate of the human species. It was brought into the public lexicon by 
                    BP as Mark Kaufman details in his <Link className="article-link" to={"https://mashable.com/feature/carbon-footprint-pr-campaign-sham"} target="_blank">article</Link> in Mashable,
                </Paragraph>

                <QuotedContent>
                    “British Petroleum…hired the public relations professionals Ogilvy & Mather to promote the slant that climate change is not the fault of an oil giant, but that of individuals. British 
                    Petroleum, or BP, first promoted and soon successfully popularized the term “carbon footprint" in the early aughts. The company unveiled its “carbon footprint calculator” in 2004 so one 
                    could assess how their normal daily life — going to work, buying food, and (gasp) traveling — is largely responsible for heating the globe. A decade and a half later, “carbon footprint” is 
                    everywhere.”
                </QuotedContent>

                <Paragraph>
                    The energy shifts from blaming oil and gas companies for their gargantuan role in destroying the planet to individuals and the individual choices made as a consumer. The fossil fuel 
                    companies simply provide you with the means to consume. If you just didn’t want to consume as much as you do, the world wouldn’t be burning like it is now and those companies wouldn’t exist 
                    anymore.<br/><br/> 

                    Ah free market logic, so infallible.<br/><br/>

                    Just kidding, it is very fallible. This line of thinking ignores the fact that the world we live in has been structured in a way that gives us very little choice in regard to our 
                    consumption of certain goods and commodities and our ability to change those consumption patterns.<br/><br/>

                    According to the <Link className="article-link" to={"https://www.epa.gov/ghgemissions/global-greenhouse-gas-emissions-data"} target="_blank">EPA</Link>, the transportation sector emits 14% of greenhouse gasses and “Almost all (95%) of the world's transportation energy comes from petroleum-based fuels, largely gasoline 
                    and diesel”. Most cities in the US <Link className="article-link" to={"https://www.smartcitiesdive.com/news/us-cities-less-walkable-than-international-counterparts-study/587046/"} target="_blank">aren’t walkable</Link> and don’t have an adequate <Link className="article-link" to={"https://www.vox.com/2015/8/10/9118199/public-transportation-subway-buses"} target="_blank">public transportation system</Link>. You must have a car and give your hard earned dollars to oil and gas companies in 
                    order to go anywhere.<br/><br/>

                    You can forgo the gas powered car altogether and switch to an electric vehicle. However, the <Link className="article-link" to={"https://finance.yahoo.com/news/5-hidden-costs-electric-vehicles-120044343.html#:~:text=%E2%80%9CThe%20average%20price%20for%20a,up%20a%20potential%20%2420%2C000%20difference.%E2%80%9D"} target="_blank">upfront cost</Link> of EVs put them out of reach for most Americans and the fueling station 
                    infrastructure in place for gas powered vehicles is <Link className="article-link" to={"https://www.visualcapitalist.com/interactive-ev-charging-stations-across-the-u-s-mapped/#:~:text=Electric%20Vehicle%20Charging%20Stations%20Across%20America%3A%20Mapped&text=Currently%2C%20the%20U.S.%20has%20about,fueling%20stations%20in%20the%20country."} target="_blank">nonexistent</Link> for electric vehicles. Not to mention that EVs are only as clean as the source of its electricity which in most cases is 
                    coming from fossil fuels.<br/><br/> 

                    Another factor individual consumers have no control over is the influence that oil and gas companies have on the body politic.<br/><br/> 

                    For decades now, oil and gas companies have been <Link className="article-link" to={"https://www.opensecrets.org/federal-lobbying/industries/summary?cycle=2023&id=E01"} target="_blank">lobbying</Link> the US government and showering our feckless politicians with millions of dollars in campaign contributions to ensure 
                    endless <Link className="article-link" to={"https://www.eesi.org/papers/view/fact-sheet-fossil-fuel-subsidies-a-closer-look-at-tax-breaks-and-societal-costs"} target="_blank">taxpayer funded subsidies</Link> keep coming their way even when they rake in <Link className="article-link" to={"https://www.energymonitor.ai/finance/big-oil-profits-soared-to-nearly-200bn-in-2022/"} target="_blank">record profits</Link>.<br/><br/> 

                    At the same time, investment into renewable energy and technology is nowhere near what it needs to be. <Link className="article-link" to={"https://influencemap.org/report/Big-Oil-s-Agenda-on-Climate-Change-2022-19585"} target="_blank">According</Link> to InfluenceMap, a think tank that monitors corporations and their 
                    involvement in lobbying regarding climate change,
                </Paragraph>

                <QuotedContent>
                    “only 12% of the five companies' (BP, Shell, Chevron, ExxonMobil, & TotalEnergies) 2022 capital expenditure (CAPEX) is forecasted to be dedicated to 'low carbon' activities. Additionally, none 
                    of the supermajors' forecasted oil production appears in line with the International Energy Agency's Net Zero Emissions by 2050 (as of Q4 2021), with several companies planning to increase 
                    oil and gas production between 2021 and 2026.”
                </QuotedContent>

                <Paragraph>
                    Thanks to their ownership of our government, new <Link className="article-link" to={"https://www.npr.org/2023/03/29/1166802809/gulf-of-mexico-oil-gas-leases-drilling"} target="_blank">leases</Link> for oil and gas extraction in the Gulf of Mexico have been selling like hotcakes and large scale oil projects like the Willow 
                    project, an oil drilling endeavor spearheaded by ConocoPhillips that is estimated to “produce 260 million metric tons of heat-trapping gases over 30 years” according to <Link className="article-link" to={"https://earthjustice.org/article/willow-project-federal-fossil-fuel-leasing"} target="_blank">EarthJustice</Link> have 
                    been greenlit, while investment into green and renewable technologies still lags behind what is necessary to get the planet back on track.<br/><br/>  

                    There is opportunity abound within green and renewable technology considering the amount of new jobs that would be available for people, new patents that could be created here, so we not 
                    only get the benefit of moving towards net zero carbon emissions, but we also see an economic boom as well through the creation of a new trailblazing industry.<br/><br/>

                    Yet it’s 2023 and we’re still <Link className="article-link" to={"https://investingnews.com/daily/resource-investing/energy-investing/oil-and-gas-investing/top-oil-producing-countries/"} target="_blank">drill baby drilling</Link> as the Earth continues to beg and plead for us humans to stop. Systemic change is needed to root out the corporate capture of our government 
                    and restructure the use of our natural resources but systemic change is out of the purview of a consumer.<br/><br/> 

                    Under a capitalist organization of the economy, profitability, not sustainability, is what matters. Most, if not, all products available to consumers for purchase were made through the 
                    exploitation of cheap labor and the use of fossil fuels during the product’s production process. Making sustainable products using clean energy sources and well compensated labor is not 
                    nearly as profitable as burning fossil fuels and using the cheapest labor possible to produce the same product. Since it turns more of a short term profit to destroy the planet and 
                    continue production in an unsustainable way, companies will continue to do so.<br/><br/>

                    There’s no such thing as ethical consumption. There’s no such thing as green consumption. Any kind of consumption in a capitalist economy has an indirect role in the emission of metric tons 
                    of greenhouse gas emissions into the atmosphere.<br/><br/>

                    Are people supposed to just stop their consumption and wither away? No, people still have to buy food and go to work and live their lives. We have no control in how this world was made and 
                    for whom it was made for. Therefore, the lion’s share of the blame shouldn’t lie on the shoulders of regular people who are the ones filling their tanks with oil to simply fulfill their 
                    needs to live in this world, it should lie on the minority of people who significantly profit off of the extraction and continued reliance on such commodities.<br/><br/>
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default RegularPeopleClimateChange;