import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, QuotedContent, ArticleContainer } from "../styles/ArticleStyles";

const CaseForBiden = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1689725144/samples/persnickety/bidenIceCream_rnp6dg.jpg" alt="The Begrudging Case for Biden in 2024" />
            
            <Title>The Begrudging Case for Biden in 2024</Title>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>July 18th, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    Gone are the days of the New Deal Democrat.<br/><br/> 

                    The wins of the working class died alongside FDR. Ever since then, we’ve never had a president champion the plight of the proletariat as much as that man. In fact, he fought so hard for 
                    regular people that he was democratically elected by the people of this nation 4 times. 6 years after his death, the Republican controlled Congress <Link className="article-link" to={"https://constitutioncenter.org/blog/how-we-wound-up-with-the-constitutions-only-term-limits-amendment"} target="_blank">pushed through the 22nd Amendment</Link> placing a 
                    two term limit on the presidency, ensuring another president of FDR’s ilk would only have two terms max to deliver for regular hard working Americans and not for the elite monied interests.<br/><br/>   

                    What we’ve seen since would make FDR turn in his grave. The Democratic party is unrecognizable now. As it stands, our two major parties act as a corporate duopoly. One is quite open about 
                    that fact, the other cosplays as a fighter for the people.<br/><br/>

                    Clearly, I hate the Democratic party. More so than I do the Republicans. At least they don’t pretend to care about us.<br/><br/> 

                    But,<br/><br/>

                    And this but pains me to say because I cannot emphasize enough the amount of disdain I hold in my heart toward these gremlins, but they are marginally better than their more extreme corporate 
                    counterparts. Are Democrats too similar to Republicans these days? Sure. You can point to things like NAFTA and its <Link className="article-link" to={"https://www.epi.org/blog/naftas-impact-workers/"} target="_blank">impact on American workers</Link>, bailouts during the Great Recession 
                    that <Link className="article-link" to={"https://thetexasorator.com/2022/05/04/the-financial-bailout-of-2008-corruption-and-finance-industry-to-blame/"} target="_blank">overwhelmingly helped the wealthy and corporations</Link>, or the <Link className="article-link" to={"https://www.defense.gov/News/Releases/Release/Article/3326875/department-of-defense-releases-the-presidents-fiscal-year-2024-defense-budget/#:~:text=On%20March%209%2C%202023%2C%20the,billion%20more%20than%20FY%202022."} target="_blank">infinite amount of money</Link> poured into the military budget year over year, and say yeah, there is 0 difference between the two 
                    parties.<br/><br/>

                    That is not true.<br/><br/>

                    I know, I know, you’re sick of the lesser of two evils argument, trust me, I know. The campaign hook of, “Hey we’re not like these crazies so vote for us :D” angers me to no end. There are 
                    however some tangible differences between Democrats and Republicans.<br/><br/>

                    Unfortunately, we live in a political system that only gives us two political parties to choose from and the system makes sure certain choices that harken back to the days of FDR 
                    are <Link className="article-link" to={"https://www.npr.org/sections/thetwo-way/2016/07/23/487179496/leaked-democratic-party-emails-show-members-tried-to-undercut-sanders"} target="_blank">thwarted</Link>. So when Joe Biden inevitably wins the Democratic nomination for president, and wannabe dictator Donald Trump wins the Republican nomination (although the former president is being 
                    brought up on <Link className="article-link" to={"https://www.politico.com/interactives/2023/trump-criminal-investigations-cases-tracker-list/"} target="_blank">37 felony counts</Link> for mishandling classified documents so who knows), we will be given the same slop to pick from.<br/><br/> 

                    When that unfortunate day in November comes and you pinch your nose and cast your vote, I will try and make the best case for Joe Biden as best I can without being a cheerleader.<br/><br/>

                    <h1>The Military</h1>
                    Much to my surprise, Joe Biden <Link className="article-link" to={"https://apnews.com/article/afghanistan-islamic-state-group-dd43bcc58bd17668b1cf4ae79997142b"} target="_blank">ended the war in Afghanistan</Link>. Like actually ended it. In the 2 decades we spent fighting an <Link className="article-link" to={"https://www.e-ir.info/2013/11/06/was-the-nato-invasion-of-afghanistan-legal/"} target="_blank">illegal war</Link> against a country that didn’t attack us, we spent 
                    around <Link className="article-link" to={"https://watson.brown.edu/costsofwar/figures/2021/human-and-budgetary-costs-date-us-war-afghanistan-2001-2022#:~:text=Figures-,Human%20and%20Budgetary%20Costs%20to%20Date%20of%20the%20U.S.%20War,in%20both%20Afghanistan%20and%20Pakistan."} target="_blank">$2.3 trillion dollars</Link> and this amount does not include the funds the US government is obligated to spend on lifelong care for the veterans that suffered through this unjustifiable 
                    war. <Link className="article-link" to={"https://apnews.com/article/middle-east-business-afghanistan-43d8f53b35e80ec18c130cd683e1a38f"} target="_blank">3592 US and Allied service members died</Link> as a direct result of the war (this figure does not include suicides after coming back from deployment). More than <Link className="article-link" to={"https://watson.brown.edu/costsofwar/costs/human/civilians/afghan"} target="_blank">70,000 innocent Afghan civilians died</Link> as 
                    a direct result of the war. The land is now littered with mines and explosives that kill and injure tens of thousands of Afghans, especially children as they go about their regular life. 
                    Defense contractors and weapons manufacturers like Lockheed Martin whose business model is war made out like bandits as a result of all of the death and despair, <Link className="article-link" to={"https://watson.brown.edu/costsofwar/papers/2021/ProfitsOfWar"} target="_blank">receiving $75 billion</Link> in 
                    contracts from the Pentagon in 2020 alone.<br/><br/>     

                    George Bush started the wars, Obama & Trump did nothing to stop it. Biden finally put an end to it. No more Joes and Janes from around the country putting their lives on the line. No more 
                    innocent civilians dying as a direct result of our actions. No more money wasted on a forever war that enriched the pockets of an abhorrent few.<br/><br/> 

                    Don’t get it twisted, we still have troops in <Link className="article-link" to={"https://www.pbs.org/newshour/world/why-u-s-forces-remain-in-iraq-20-years-after-shock-and-awe"} target="_blank">Iraq</Link> and <Link className="article-link" to={"https://apnews.com/article/syria-us-troops-drone-attack-6194dca97f594e3609914637463c4ce3"} target="_blank">Syria</Link>, we continue to supply <Link className="article-link" to={"https://www.pbs.org/newshour/politics/u-s-approves-massive-arms-sale-to-saudi-arabia-united-arab-emirates-to-counter-iran"} target="_blank">Saudi Arabia</Link> and <Link className="article-link" to={"https://www.reuters.com/business/aerospace-defense/biden-administration-approved-735-million-arms-sale-israel-sources-2021-05-17/"} target="_blank">Israel</Link> with weapons so they can continue their ruthless onslaught on the innocent Yemeni 
                    and Palestinian people, along with countless other countries where we still have a significant military presence meddling in affairs we have no business meddling in. Biden is no man of peace. 
                    But credit where credit is due, he had the stones to do something his two predecessors didn’t have. Ending an offensive and illegal war is good. Continuing an offensive and illegal war is 
                    bad.<br/><br/>

                    <h1>Labor</h1>{/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    There have been concrete improvements for workers and labor unions in this country ever since Biden’s <Link className="article-link" to={"https://www.fisherphillips.com/en/news-insights/fully-constituted-biden-nlrb-is-here.html"} target="_blank">new appointments</Link> to the NLRB. With <Link className="article-link" to={"https://theintercept.com/2022/04/22/amazon-warehouse-union-biden-nlrb/"} target="_blank">help</Link> from the newly minted pro-union NLRB leadership, 
                    there have been new unionization efforts and <Link className="article-link" to={"https://theintercept.com/2022/04/02/amazon-union-staten-island/"} target="_blank">victories</Link> across several powerful corporations like Amazon and <Link className="article-link" to={"https://truthout.org/articles/starbucks-workers-officially-reach-200-union-election-victories/"} target="_blank">Starbucks</Link> that haven't been seen in quite a while.<br/><br/>  

                    They’ve <Link className="article-link" to={"https://www.nytimes.com/2023/03/01/business/economy/starbucks-union-buffalo-ruling.html"} target="_blank">cracked down</Link> on egregious labor law violations committed by these corporate behemoths and have overseen a <Link className="article-link" to={"https://www.nlrb.gov/news-outreach/news-story/correction-first-three-quarters-union-election-petitions-up-58-exceeding"} target="_blank">skyrocketing</Link> of union election petitions. Something that was nonexistent with 
                    the NLRB under the Trump administration.<br/><br/> 

                    Even if your own workplace isn’t unionized, victories for one set of workers are victories for all. The more workplaces that become unionized, the more pressure there is on upper management 
                    of other companies to treat their workers like human beings with dignity and offer better working conditions and compensation. As they say, a rising tide lifts all boats.<br/><br/>

                    On top of the progress made on unions, worker’s pay has had some victories as well thanks to Sleepy Joe. The administration <Link className="article-link" to={"https://www.ny1.com/nyc/all-boroughs/news/2022/01/21/minimum-wage-federal-workers-increase-january-biden-#:~:text=Biden%27s%20action%20also%20included%20an,Trump%2Dera%20executive%20order%20about"} target="_blank">raised the minimum wage</Link> for federal employees to $15 an hour which 
                    will affect tens of thousands of people from cleaning professionals to maintenance workers to nursing assistants to food service workers and many more types of workers.<br/><br/> 

                    Now, there is still a lot of work to be done for labor in this country as the federal minimum wage is still at a <Link className="article-link" to={"https://www.dol.gov/agencies/whd/minimum-wage/history"} target="_blank">whopping $7.25</Link>. Unionization rates are actually at the lowest on record 
                    at <Link className="article-link" to={"https://www.bls.gov/news.release/pdf/union2.pdf"} target="_blank">10.1%</Link> as there has been a large increase in the number of non-union wage and salary workers entering the workforce, thus lowering the overall union membership rate. Still, it cannot be denied 
                    that workers are in a better position now than under a Trump Republican administration.<br/><br/>


                    <h1>Student Debt</h1>
                    In the olden days, education used to be an affordable path to a steady job and a beautiful home, giving you the freedom to have a decent life and start a family if you so chose (significantly 
                    skewed towards white people but that’s neither here nor there). Then somewhere along the line, the ghouls in power thought, ‘Hey, you know what would be a great idea? What if we made the thing 
                    that gave people a chance at a significantly better life really expensive so regular people would have to cripple their entire future with debilitating debt in order for them to become more 
                    desperate and subservient workers that will accept worse working conditions and lower pay because they have no choice since they’re buried in debt?’.<br/><br/> 

                    Then, a couple decades pass and all of a sudden, we’re sitting on a colossal student debt crisis totalling <Link className="article-link" to={"https://educationdata.org/student-loan-debt-crisis"} target="_blank">$1.75 trillion dollars</Link> where the average total debt balance comes out to $40,505, 
                    which is more than the <Link className="article-link" to={"https://propertyclub.nyc/article/what-is-the-average-down-payment-on-a-house"} target="_blank">average down payment</Link> on a house in most states.<br/><br/>

                    To combat this societal ill, the Biden administration plans to forgive up to <Link className="article-link" to={"https://www.whitehouse.gov/briefing-room/statements-releases/2022/08/24/fact-sheet-president-biden-announces-student-loan-relief-for-borrowers-who-need-it-most/"} target="_blank">$20,000 in student loans</Link> for Pell Grant recipients and $10,000 for non-Pell Grant recipients with income caps at 
                    $125,000 & $250,000 for married couples. Because the working class can have nothing nice on this planet without fighting tooth and nail, the judicial activists over at the Supreme Court 
                    have <Link className="article-link" to={"https://www.scotusblog.com/2023/06/supreme-court-strikes-down-biden-student-loan-forgiveness-program/"} target="_blank">struck down</Link> the student-loan forgiveness plan in a 6-3 ruling.<br/><br/> 

                    What’s rich is that <Link className="article-link" to={"https://www.motherjones.com/politics/2021/09/heres-the-truth-about-brett-kavanaughs-finances/"} target="_blank">according to</Link> senior reporter Stephanie Mencimer at Mother Jones, one of the Trump appointed justices, Brett Kavanaugh 

                </Paragraph>

                    <QuotedContent>
                        “<Link className="article-link" to={"https://fixthecourt.com/wp-content/uploads/2018/01/Kavanaugh-2016-FD.pdf"} target="_blank">reported owing</Link> between $60,004 and $200,000 on three credit cards and a loan against his retirement account. By the time Trump nominated him to the high court in July 2018, <Link className="article-link" to={"https://fixthecourt.com/wp-content/uploads/2018/07/BMK-nominee-disclosure-net-worth.pdf"} target="_blank">those debts had vanished</Link>.
                        Overall, his reported income and assets didn’t seem sufficient to pay off all that debt while maintaining his upper-class lifestyle: an expensive house in an exclusive suburban 
                        neighborhood, two kids in a $10,500-a-year private school, and a membership in a posh country club reported to charge $92,000 in initiation fees. His financial disclosure forms have raised 
                        more questions than they’ve answered, leading to speculation about whether he’s had a private benefactor and what sorts of conflicts that relationship might entail."
                    </QuotedContent>

                <Paragraph>

                    Debt relief for me but not for thee.<br/><br/> 

                    Thankfully, our sleepy president is showing some fight, <Link className="article-link" to={"https://rollcall.com/2023/06/30/biden-launches-new-effort-to-salvage-student-loan-relief-plan/"} target="_blank">invoking the Higher Education Act of 1965</Link> to push the student debt relief plan through to circumvent the Supreme Court’s awful decision.<br/><br/>   

                    Let’s be clear though, this is a bandaid. Biden <Link className="article-link" to={"https://www.youtube.com/watch?v=nL2SbqGKu2w&ab_channel=MorePerfectUnion"} target="_blank">has the authority</Link> to wipe the debt slate clean for everyone through executive order as <Link className="article-link" to={"https://www.nerdwallet.com/article/loans/student-loans/student-loan-debt#:~:text=Most%20student%20loans%20%E2%80%94%20about%2092,the%20U.S.%20Department%20of%20Education."} target="_blank">92%</Link> of the student debt is owned by the US Department of 
                    Education. However, having a president that will fight for his proposed legislation even when it’s struck down by a rogue court and being $30,505 in the hole as opposed to $40,505 is 
                    definitely a slight improvement for our 43 million Americans saddled with such debt and is something we wouldn’t even be able to sniff at with a Republican administration.<br/><br/> 

                    <h1>Gun Laws</h1>
                    For decades now, there have been way too many guns which are way too accessible for just about anyone to get in this country. Shooting after shooting, dead baby after dead baby, and we still 
                    saw no movement on the path towards a safer America.<br/><br/>

                    That is until last year, on the heels of the horrific Buffalo and Uvalde shootings, when Joe Biden <Link className="article-link" to={"https://www.npr.org/2022/06/25/1107626030/biden-signs-gun-safety-law"} target="_blank">signed into law</Link> the first major gun safety bill in 30 years. The bill strengthened red-flag 
                    laws and incentivized states to allow groups to petition courts to remove weapons from people deemed a threat to themselves and/or others. It expanded a law that prevents people who have been 
                    convicted of domestic abuse to also include dating partners rather than just spouses or former spouses. Most importantly, it expanded and strengthened background checks on people between the 
                    ages of 18-21 seeking to buy a gun as some of the most barbarous and vicious shootings we have seen thus far have been committed by young men in this age range.<br/><br/>  

                    In addition, he also <Link className="article-link" to={"https://www.forbes.com/sites/alisondurkee/2023/03/14/biden-announces-new-gun-control-measures-heres-what-his-executive-order-will-do/?sh=71b59ff44db0"} target="_blank">issued an executive order</Link> in March, further bolstering federal laws on background check requirements for anyone selling firearms, preventing dealers whose federal licenses 
                    have been revoked from continuing to sell firearms, and modernizing the Undetectable Firearms Act of 1988, which is set to expire in December, to make it harder to produce firearms that can 
                    evade metal detectors among other things.<br/><br/>

                    I had no idea there were firearms that were undetectable by metal detectors but I feel just a little less frightened to death now that action is being taken to curb its production.<br/><br/>    

                    We all know Republican politicians wouldn’t lift a finger to change our way too lenient gun laws in this country. That would mean they’d have to kiss all that NRA money goodbye. <Link className="article-link" to={"https://ballotpedia.org/Bipartisan_Safer_Communities_Act_of_2022"} target="_blank">193 Republicans </Link>
                    in the house and 33 in the Senate voted against the Bipartisan Safer Communities Act Biden signed into law last year. This was after 19 children and 2 faculty members were shot and murdered at 
                    their school in Uvalde.<br/><br/> 

                    There’s still a lot of meat left on the bone with this issue. There still aren't universal background checks mandated by law, states need to opt in to the red flag law provisions, and mental 
                    health accessibility and resources are still inadequate for poorer individuals. However, it is undeniable the progress that has been made by the Biden administration on this issue and it cannot 
                    go unnoticed.<br/><br/> 

                    <span>▪️▪️▪️</span><br/>

                    In a lot of ways, Joe Biden has been the bland corporate status quo manager I expected him to be. Yet, there have been shades of based Biden that have taken me by surprise in the best way 
                    imaginable.<br/><br/> 

                    I thought we’d be in Afghanistan forever. I was wrong. I thought students would continue to pay for the predatory pricing of their education with no help whatsoever. I was wrong. I thought I’d 
                    continue to watch school shooting after school shooting with 0 progress made on gun control like it has always been. I was wrong. I thought unions would continue to be crushed and suppressed as 
                    they have been for decades and decades. That still might be the case but at least there’s some hope the tide is changing on that front!<br/><br/> 

                    These are significant policy moves unequivocally in the right direction that we should’ve gotten with Obama, and most definitely won’t get with 4 more years of Donnie boy.<br/><br/> 

                    Until a true fighter for the working class comes across our ballots, we’re going to be voting for either Joe Biden or Donald Trump in the next general election for president. Given these nausea 
                    inducing choices, it is clear Biden is the much better one by a mile.<br/><br/>

                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default CaseForBiden;