import React from "react";
import { pageAnimation } from "../animation";
import { Link } from "react-router-dom";
import { ArticleStyle, ArticleImage, Title, NameAndDate, Paragraph, ArticleContainer, Subhead } from "../styles/ArticleStyles";

const GunsAreBad = () => {
    return (
        <ArticleStyle
            variants={pageAnimation} 
            initial="hidden" 
            exit="exit" 
            animate="show"
        >
            {/* <ArticleImageContainer> */}
                <ArticleImage src="https://res.cloudinary.com/djr5bxwfk/image/upload/v1699204956/samples/persnickety/gunsAreBad_ux6tya.jpg" alt="Guns are Bad" />
            {/* </ArticleImageContainer> */}
            <Title>Guns are Bad</Title>
            <Subhead>I know what a revolutionary groundbreaking never been said before spicy take.</Subhead>
            <NameAndDate>
                <h3>by Shenal Tissera</h3>
                <h4>November 3rd, 2023</h4>
            </NameAndDate>
            <ArticleContainer>
                <Paragraph>
                
                    {/* <Link className="article-link" to={""} target="_blank"></Link> */}
                    After what seems like the 1,794th horrific mass shooting in America, with 18 people left dead and 13 others injured in Lewiston, Maine, the country is reeling. Well, regular people with 
                    an iota of humanity are reeling, the legislators and interest groups that represent gun manufacturers are yawning.<br/><br/>

                    Where there are more guns, there are more gun deaths. Where there are less guns, there are less gun deaths. Insanely complex logic, I know.<br/><br/> 

                    For some reason however, there are <Link className="article-link" to={"https://www.smallarmssurvey.org/sites/default/files/resources/SAS-Press-release-global-firearms-holdings.pdf"} target="_blank">more guns than there are actual people</Link> in the US.<br/><br/>

                    You’d think after the barbaric slaughter of children and faculty in classrooms across the nation in <Link className="article-link" to={"https://www.history.com/this-day-in-history/gunman-kills-students-and-adults-at-newtown-connecticut-elementary-school"} target="_blank">Newtown</Link> or <Link className="article-link" to={"https://www.nbcnews.com/news/us-news/police-respond-shooting-parkland-florida-high-school-n848101"} target="_blank">Parkland</Link> or <Link className="article-link" to={"https://www.texastribune.org/2023/05/24/uvalde-school-shooting-what-to-know/"} target="_blank">Uvalde</Link> or <Link className="article-link" to={"https://www.nytimes.com/article/nashville-school-shooting.html"} target="_blank">Nashville</Link> or <Link className="article-link" to={"https://courses.bowdoin.edu/history-2203-fall-2020-ecahill2/narrative-of-the-event/"} target="_blank">Columbine</Link> or <Link className="article-link" to={"https://www.history.com/this-day-in-history/massacre-at-virginia-tech-leaves-32-dead"} target="_blank">Virginia Tech</Link> or <Link className="article-link" to={"https://www.cnn.com/interactive/2023/09/us/umpqua-shooting-roseburg-oregon-cnnphotos/"} target="_blank">Roseburg</Link> or 
                    <Link className="article-link" to={"https://www.npr.org/sections/health-shots/2022/08/12/1116715147/texas-school-shooting-santa-fe-high-school"} target="_blank">Santa Fe</Link> or many other schools, that something would be done about guns in this country.<br/><br/> 

                    If not that, then maybe the shootings in <Link className="article-link" to={"https://buffalonews.com/news/local/complete-coverage-10-killed-3-wounded-in-mass-shooting-at-buffalo-supermarket/collection_e8c7df32-d402-11ec-9ebc-e39ca6890844.html"} target="_blank">Buffalo</Link> or <Link className="article-link" to={"https://www.history.com/this-day-in-history/charleston-ame-church-shooting"} target="_blank">Charleston</Link> or the <Link className="article-link" to={"https://www.npr.org/2016/06/16/482322488/orlando-shooting-what-happened-update"} target="_blank">Pulse night club</Link> or <Link className="article-link" to={"https://apnews.com/article/el-paso-walmart-texas-crusius-bf7d25f3567959ee8b121deabcf1d9a1"} target="_blank">El Paso</Link> or <Link className="article-link" to={"https://www.nytimes.com/2017/11/05/us/church-shooting-texas.html"} target="_blank">Sutherland Springs</Link> or <Link className="article-link" to={"https://abcnews.go.com/US/back-aurora-colorado-movie-theater-shooting-years/story?id=48730066"} target="_blank">Aurora</Link>, where innocent civilians going about their daily lives 
                    were gunned down like they’re in a warzone, would light a fire under those in power.<br/><br/> 

                    After the deadliest mass shooting ever with <Link className="article-link" to={"https://www.ajc.com/news/national/the-deadly-las-vegas-massacre-the-numbers-how-many-killed-injured-and-more/aFVWCLNOjBcjx0TUApdkKL/"} target="_blank">more than 550 casualties at the Route 91 Harvest music festival in Las Vegas</Link>, certainly something would have to have been done about guns.<br/><br/> 

                    Right?<br/><br/>

                    Not in the good ‘ol U-S-of-A baby, the greatest country on planet earth. The only place on this giant rock where there’s a small chance your child might get headshotted at school by a 
                    trigger happy psychopath because freedom.<br/><br/> 

                    WOOHOO!<br/><br/> 

                    GUNS!<br/><br/> 

                    EXPLOSIONS!<br/><br/>

                    AMERICA!<br/><br/>

                    According to the <Link className="article-link" to={"https://www.nraila.org/"} target="_blank">National Rifle Association Insitite for Legislative Action</Link>, Maine <Link className="article-link" to={"https://www.nraila.org/gun-laws/state-gun-laws/maine/"} target="_blank">does not require permits to purchase rifles, shotguns or handguns</Link>, nor does it require registration of 
                    the firearm or licensing of the owner.<br/><br/> 

                    In 2015, <Link className="article-link" to={"https://www.maine.gov/dps/msp/licenses-permits/concealed-carry-maine"} target="_blank">Maine passed a permitless carry law</Link> that allows individuals to openly carry a firearm in public without a permit.<br/><br/> 

                    They <Link className="article-link" to={"https://everytownresearch.org/rankings/state/maine/"} target="_blank">do not require background checks</Link> at the point of sale for firearms.<br/><br/>

                    There are <Link className="article-link" to={"https://everytownresearch.org/rankings/law/extreme-risk-law/"} target="_blank">no Extreme Risk laws put in place</Link> for law enforcement or family and friends to intervene when a person who is at risk of harming themselves or others attempts to purchase a firearm.<br/><br/> 

                    There are <Link className="article-link" to={"https://everytownresearch.org/rankings/law/assault-weapons-prohibited/"} target="_blank">no bans on assault weapons</Link>.<br/><br/>
                    
                    There are <Link className="article-link" to={"https://everytownresearch.org/rankings/law/high-capacity-magazines-prohibited/"} target="_blank">no bans on purchasing high-capacity magazines</Link>.<br/><br/>

                    Robert Card, the man who murdered 18 people and left another 13 injured, carried out his heinous rampage with what officials described as an <Link className="article-link" to={"https://www.cbsnews.com/news/who-is-robert-card-confirmed-details-maine-shooting-suspect-person-of-interest/"} target="_blank">assault rifle with an extended magazine and scope</Link>. Law enforcement 
                    also confirmed that the firearm was purchased legally under Maine’s gun laws.<br/><br/>

                    The Sagadahoc County Sheriff’s Office was notified of Card’s erratic and paranoid behavior weeks before the mass shooting occurred.<br/><br/>

                    Both by his <Link className="article-link" to={"https://www.pressherald.com/2023/11/05/everyone-feared-the-worst-about-robert-card-no-one-stopped-him/"} target="_blank">family and friends</Link> and <Link className="article-link" to={"https://www.cbsnews.com/news/maine-shooting-robert-card-police-check-army/"} target="_blank">the Army</Link>.<br/><br/> 

                    18 people didn’t have to die on October 25. This was preventable.<br/><br/> 

                    When someone talks about how they’re going to go and shoot up an army base or a store or fill in the blank, the only thing to do is restrict that person from access to any and all firearms 
                    and confiscate any firearms that person has currently. That is the common sense thing to do. You’re not restricting the person’s freedoms. You’re keeping the freedom of regular people to 
                    go about their daily lives without fear of getting murdered at their local Walmart.<br/><br/> 

                    In a perfect world, citizens wouldn’t have to be armed.<br/><br/>

                    Let’s stop pretending like the citizenry must be armed to halt government tyranny if it happens. The government has more than muskets at their disposal these days and there already has 
                    been tyranny going on for a long long time and it hasn’t been close to ending, much less at the hands of armed citizens.<br/><br/>

                    Countries like Singapore, South Korea and Japan have the <Link className="article-link" to={"https://worldpopulationreview.com/country-rankings/gun-ownership-by-country"} target="_blank">lowest gun ownership rates among all countries</Link> as well as strict gun regulations and wow would you look at that, they also have 
                    the <Link className="article-link" to={"https://worldpopulationreview.com/country-rankings/gun-deaths-by-country"} target="_blank">lowest firearm-related death rates among all countries</Link> as well.<br/><br/> 

                    Color me surprised.<br/><br/> 

                    It’s an absolute pipe dream to imagine an America completely free of guns, the cat has been let out of the bag and there are now millions and millions of cats all across the country.<br/><br/>

                    However, we don’t have to accept these needless massacres as just a byproduct of freedom. It doesn’t have to be this way. The <Link className="article-link" to={"https://constitution.congress.gov/browse/amendment-2/#:~:text=Second%20Amendment%20Right%20to%20Bear,Arms%2C%20shall%20not%20be%20infringed."} target="_blank">word ‘regulated’ is literally used</Link> in the 2nd Amendment. If 
                    you are okay with innocent civilians getting murdered in broad daylight, then by all means be willy nilly with gun ownership and allow permitless carry of guns with no background checks 
                    conducted and zero red flag laws. If you do prioritize the safety and wellbeing of people in the country though, then laws need to change now.<br/><br/> 

                    We need much stricter universal background checks. We need discriminatory restrictions placed on former felons and individuals with severe mental health issues. Red flag laws need to be 
                    instituted and mandated everywhere. Require permits and licenses for any and all firearms purchased. Ban assault weapons. Ban high-capacity magazines.<br/><br/> 

                    Not to mention we should provide healthcare for every citizen to ensure they have help, whether it be physical or mental, when needed.<br/><br/> 

                    The idea that guns make us safer is laughable. If that were the case, the United States of America would be the safest nation that has ever existed.<br/><br/> 

                    Yet, we seem to be the only country in the developed world where these kinds of atrocities are commonplace.<br/><br/>
 
                </Paragraph>
            </ArticleContainer>
        </ArticleStyle>
    )
}

export default GunsAreBad;